import {useAppSelector} from "../../../../../_store/hooks";
import useCurrentUserRole from "../../../../hooks/useCurrentUserRole";
import {Skeleton} from "../../../../primitives/Skeleton";
import {
	AdminNavigationSection,
	CorporateNavigationSection,
	CustomerServiceNavigationSection,
	InfluencerNavigationSection,
	PartnerNavigationSection,
	SponsorNavigationSection,
} from "../../role-based";
import {CustomerServiceL1NavigationSection} from "../../role-based/customer-service-l1";
import {MarketingNavigationSection} from "../../role-based/marketing";

export const RoleBasedNavigation = () => {
	const appLoading = useAppSelector(state => state.appStatus.loading);
	const userLoading = useAppSelector(state => state.user.loading);

	return userLoading || appLoading ? (
		<div className="mx-4 flex flex-col gap-2">
			<Skeleton className="mt-2 h-12 bg-navigation-foreground/10" />
			<Skeleton className="h-12 bg-navigation-foreground/10" />
			<Skeleton className="h-12 bg-navigation-foreground/10" />
		</div>
	) : (
		<RoleBasedNavigationSections />
	);
};

const RoleBasedNavigationSections = () => {
	const {activeRole: currentUserRole} = useCurrentUserRole();

	switch (currentUserRole) {
		case "admin":
			return <AdminNavigationSection />;
		case "corporate":
			return <CorporateNavigationSection />;
		case "customer_service":
			return <CustomerServiceNavigationSection />;
		case "customerservice_l1":
			return <CustomerServiceL1NavigationSection />;
		case "partner":
			return <PartnerNavigationSection />;
		case "sponsor":
			return <SponsorNavigationSection />;
		case "influencer":
			return <InfluencerNavigationSection />;
		case "marketing":
			return <MarketingNavigationSection />;
		default:
			return (
				<div className="m-4 rounded-md border border-destructive/60 p-4">
					<h4 className="mb-2 scroll-m-20 border-b border-border pb-2 text-base font-semibold tracking-tight">No Disponible</h4>
					<p className="text-sm">
						Es posible que tu rol actual no te permita acceder a la navegación. Si crees que es un error contacta con soporte.
					</p>
				</div>
			);
	}
};
